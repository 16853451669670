import React from 'react';
import './header.css';
import logo from '../../assests/LMTextLogoWht.png';

function Header() {
  return (
    <header className="header">
      <img src={logo} alt="Loot Markets Logo" className="logo" />
      <h1>Coming Soon...</h1>
    </header>
  );
}

export default Header;
