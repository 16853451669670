import React from 'react';
import './content.css';

function Content() {
  return (
    <section className="content">
      <p>
        Bringing permissionless Memecoin culture to the EVM!
      </p>
      <p>
        Loot Markets is here to redefine the way users create, buy, and sell memecoins, and we're inviting YOU to join our whitelisted beta.
      </p>
      <ul>
        <li>🚀 Create and launch your own meme coins on bonding curves, effortlessly.</li>
        <li>💰 Bet on market caps and watch your creations soar across decentralized networks.</li>
        <li>⛓️ Manage memes across chains while connecting with a vibrant community of meme enthusiasts.</li>
      </ul>
      <p>
        Sign up for our exclusive beta to get access to the ultimate shitcoin trading hub. We'll be asking users to join and share their feedback with us before we get ready for our launch later this year.
      </p>
      <a
        href="https://forms.gle/F2UmRqiPVdXh3SaE8"
        target="_blank"
        rel="noopener noreferrer"
        className="beta-button"
      >
        Sign Up for the Beta
      </a>
    </section>
  );
}

export default Content;
